import React from 'react';

function YesNoModal({ isOpen, message, onYes, onNo, isLoading }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded shadow-lg max-w-md w-full">
                <h2 className="text-xl font-bold mb-4">Confirmation</h2>
                <p className="mb-4">{message}</p>
                <div className="flex justify-end space-x-4">
                    <button
                        className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
                        onClick={onNo}
                    >
                        No
                    </button>
                    <button
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                        onClick={onYes}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <div className="loader border-t-transparent border-solid animate-spin rounded-full border-gray-100 border-4 h-5 w-5 mr-2"></div>
                        ) : (
                            "Yes"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default YesNoModal;
