export const createWebSocketConnection = (onMessageCallback, onCloseCallback) => {
    let socket;

    const connectWebSocket = () => {
        try {
            socket = new WebSocket('wss://admin.wishgeekstechserve.com:8080/ws');

            socket.onopen = () => {
                console.log("Connected to WebSocket server");
            };

            socket.onmessage = (event) => {
                try {
                    const message = JSON.parse(event.data);
                    onMessageCallback(message);
                } catch (error) {
                    console.error("Error parsing WebSocket message:", error);
                }
            };

            socket.onclose = () => {
                console.log("Disconnected from WebSocket server");
                if (onCloseCallback) onCloseCallback();
            };

        } catch (error) {
            console.error("WebSocket connection error:", error);
        }
    };

    connectWebSocket();

    return () => {
        if (socket) {
            socket.close();
        }
    };
};
