import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { isAuthenticated } from '../utils/auth';

const ProtectedRoutes = ({ allowedRoles }) => {
  const location = useLocation();

  // Check if user is authenticated
  if (!isAuthenticated()) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // Get user role from storage
  const role = localStorage.getItem("role") || sessionStorage.getItem("role");

  // If user is a super-admin, grant access
  if (role === "super-admin") {
    return <Outlet />; // Render the child routes if super-admin
  }

  // Check if the user's role is allowed
  if (allowedRoles.includes(role)) {
    return <Outlet />; // Render the child routes if authorized
  } else {
    console.log("sent to not super admin page");
    return <Navigate to="/not-authorised-user" replace />; // Redirect if not authorized
  }
};

export default ProtectedRoutes;