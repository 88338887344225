import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import logo from "../../assests/image/techserve.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(""); // For showing login errors
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate(); // Initialize the router
  const location = useLocation();
  const from = location.state?.from?.pathname // Get the intended route or default to "/"

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Reset error state before submitting

    setLoading(true);
    try {
      const response = await fetch("https://wishgeekstechserve.vercel.app/api/admin-user/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password
        }),
      });

      const data = await response.json();
      setLoading(false);

      if (!response.ok) {
        throw new Error(data.message || "Login failed");
      }

      // Store token and role in localStorage or sessionStorage
      if (rememberMe) {
        localStorage.setItem("token", data.token); // Remember the token
        localStorage.setItem("role", data.role); // Save user role
      } else {
        sessionStorage.setItem("token", data.token); // Store token for the session
        sessionStorage.setItem("role", data.role); // Save user role
      }

      // Redirect to "from" location or fallback based on role
      if (from) {
        navigate(from, { replace: true }); // If there's a "from", navigate there
      } else {
        // If no "from", redirect based on role
        if (data.role === "super-admin") {
          navigate("/dashboard", { replace: true }); // Redirect to dashboard for super-admin
        } else {
          navigate("/enquiries", { replace: true }); // Redirect to enquiries for other roles
        }
      }
    } catch (error) {
      // Set error message to show to the user
      setError(error.message);
    }
  };

  return (
    <div className="container-fluid p-0 wrps">
      <div className="wrapper">
        <form onSubmit={handleSubmit}>
          <img src={logo} alt="Logo" className="w-44" />

          {error && <p className="error-message">{error}</p>} {/* Display login error */}

          <div className="input-box">
            <input
              type="text"
              placeholder="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <i className="bx bxs-user"></i>
          </div>
          <div className="input-box">
            <input
              type="password"
              placeholder="Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <i className="bx bxs-lock-alt"></i>
          </div>
          <div className="remember-forgot">
            <label>
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <span className="mx-1">Remember Me</span>
            </label>
            <a href="#">Forgot Password</a>
          </div>
          <button type="submit" className="btnlogin flex items-center justify-center text-center" disabled={loading}>
            {loading ? (
              <div className="loader border-t-transparent border-solid animate-spin rounded-full border-gray-100 border-4 h-5 w-5 mr-2"></div>
            ) : (
              "Login"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
