import React, { useEffect, useState } from "react";

const Users = () => {
    const [usersData, setUsersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetching data from the API when the component mounts
        const fetchData = async () => {
            try {
                const response = await fetch("https://wishgeekstechserve.vercel.app/api/all-tickets");
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const data = await response.json();
                setUsersData(data.enquiries || "");
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }


    return (
        <>
            <div className="container h-[100vh] overflow-y-auto bg-white p-4">
                <div className="flex items-center justify-between mb-4 mt-3 border-b border-gray-300">
                    <h1 className="text-3xl font-medium mt-24 text-gray-500">Projects</h1>
                </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200  bg-white mb-5">
                        <thead className="bg-[#338dfb] text-white">
                            <tr>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                    Project Name
                                </th>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                    Email
                                </th>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                    Project Name
                                </th>

                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                    Date
                                </th>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                    Status
                                </th>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">

                                </th>
                            </tr>
                        </thead>

                        <tbody className="bg-white divide-y divide-gray-200">
                            {usersData.length > 0 ? (
                                usersData.map((data) => (
                                    <tr key={data._id}>
                                        <td className="px-6 py-4 text-xs  border  font-medium">
                                            {data.firstName} {data.lastName}
                                        </td>
                                        <td className="px-6 py-4 text-xs  border  font-medium">
                                            {data.email}
                                        </td>
                                        <td className="px-6 py-4 text-xs  border  font-medium">
                                            {data.projectName}
                                        </td>
                                        <td className="px-6 py-4 text-xs  border  font-medium">
                                            {new Date(data.createdAt).toLocaleDateString()}
                                        </td>
                                        <td className="px-6 py-4 text-xs  border  font-medium">
                                            {data.PlanTaken || "N/A"}
                                        </td>
                                        <td className="px-6 py-4 text-xs border font-medium ">
                                            
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan="6"
                                        className="px-6 py-4 text-xs text-gray-500 text-center"
                                    >
                                        No data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Users;
