import React, { useEffect, useState } from "react";
import Loader from "../../component/loader/Loader";
import YesNoModal from "../../component/YesNoModal";
import { MdDelete } from "react-icons/md";

const Users = () => {
  const [usersData, setUsersData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  // Loader to be used inside buttons
  const [smallLoader, setSmallLoader] = useState(false);

  // Delete user YesNoModal
  const [showYesNoModal, setShowYesNoModal] = useState(false);

  useEffect(() => {
    // Fetch users from the API
    const fetchUsers = async () => {
      try {
        const response = await fetch("https://wishgeekstechserve.vercel.app/api/user/get-all-users");
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        const data = await response.json();
        setUsersData(data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const confirmDelete = async () => {
    setSmallLoader(true);
    try {
      const response = await fetch(`https://wishgeekstechserve.vercel.app/api/user/get-all-users`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ _id: selectedUser }),
      });

      if (response.ok) {
        setUsersData((prevData) =>
          prevData.filter((user) => user._id !== selectedUser)
        );
      } else {
        console.error("Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setSmallLoader(false);
      setShowYesNoModal(false);
    }
  };

  const triggerYesNoModal = (id) => {
    setSelectedUser(id);
    setShowYesNoModal(true);
  };

  const cancelDelete = () => {
    setShowYesNoModal(false);
    setSelectedUser(null);
  };

  return (
    <>
      <YesNoModal
        isOpen={showYesNoModal}
        message="Are you sure you want to delete this user?"
        onYes={confirmDelete}
        onNo={cancelDelete}
        isLoading={smallLoader}
      />

      <div className="container h-[100vh] overflow-y-auto bg-white p-4">
        <div className="flex items-center justify-between mb-4 mt-3 border-b border-gray-300">
          <h1 className="text-3xl font-medium mt-24 text-gray-500">Users</h1>
        </div>

        <div className="overflow-x-auto">
          {loading ? (
            <div className="h-full w-full flex items-center justify-center py-4">
              <Loader />
            </div>
          ) : (
            <table className="min-w-full divide-y divide-gray-200  bg-white mb-5">
              <thead className="bg-[#338dfb] text-white">
                <tr>
                  <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                    User Name
                  </th>
                  <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                    Email
                  </th>
                  <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                    Contact No.
                  </th>

                  <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                    Address
                  </th>
                  <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                    City, State
                  </th>
                  <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">

                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {usersData.length > 0 ? (
                  usersData.map((data) => (
                    <tr key={data._id}>
                      <td className="px-6 py-4 text-xs  border  font-medium">
                        {data.firstName} {data.lastName}
                      </td>
                      <td className="px-6 py-4 text-xs  border  font-medium">
                        {data.email}
                      </td>
                      <td className="px-6 py-4 text-xs  border  font-medium">
                        {data.phone}
                      </td>
                      <td className="px-6 py-4 text-xs  border  font-medium">
                        {data.address}
                      </td>
                      <td className="px-6 py-4 text-xs  border  font-medium">
                        {data.city} {data.state && ","} {data.state}
                      </td>
                      <td className="px-6 py-4 text-xs  border  font-medium">
                        <MdDelete className="text-red-500 text-[26px] hover:text-red-700 cursor-pointer" onClick={() => triggerYesNoModal(data._id)} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="px-6 py-4 text-xs text-gray-500 text-center"
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Users;
