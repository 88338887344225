import React, { useEffect, useState } from "react";
import Loader from "../../component/loader/Loader";
import YesNoModal from "../../component/YesNoModal";
import { MdDelete } from "react-icons/md";
import { IoMdAddCircleOutline } from "react-icons/io";

const AdminUsers = () => {
    const [usersData, setUsersData] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [loading, setLoading] = useState(true);

    // Loader to be used inside buttons
    const [smallLoader, setSmallLoader] = useState(false);

    // Delete user YesNoModal
    const [showYesNoModal, setShowYesNoModal] = useState(false);

    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("agent");

    useEffect(() => {
        // Fetch users from the API
        const fetchUsers = async () => {
            try {
                const response = await fetch("https://wishgeekstechserve.vercel.app/api/admin-user");
                if (!response.ok) {
                    throw new Error("Failed to fetch users");
                }
                const data = await response.json();
                setUsersData(data.users);
            } catch (error) {
                console.error("Error fetching users:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const handleRoleChange = async (userId, newRole) => {
        try {
            const response = await fetch("https://wishgeekstechserve.vercel.app/api/admin-user", {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ userId, newRole }),
            });

            if (response.ok) {
                // Update the user's role in the local state
                setUsersData((prevData) =>
                    prevData.map((user) =>
                        user._id === userId ? { ...user, role: newRole } : user
                    )
                );
                alert("Role updated successfully!");
            } else {
                throw new Error("Failed to update role");
            }
        } catch (error) {
            console.error("Error updating role:", error);
        }
    };


    const confirmDelete = async () => {
        setSmallLoader(true);
        try {
            const response = await fetch(`https://wishgeekstechserve.vercel.app/api/admin-user`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ _id: selectedAdmin }),
            });

            if (response.ok) {
                setUsersData((prevData) =>
                    prevData.filter((adminUser) => adminUser._id !== selectedAdmin)
                );
            } else {
                console.error("Failed to delete admin user");
            }
        } catch (error) {
            console.error("Error deleting admin user:", error);
        } finally {
            setSmallLoader(false);
            setShowYesNoModal(false);
        }
    };

    const triggerYesNoModal = (id) => {
        setSelectedAdmin(id);
        setShowYesNoModal(true);
    };

    const cancelDelete = () => {
        setShowYesNoModal(false);
        setSelectedAdmin(null);
    };

    const handleAddUser = async () => {
        try {
            // Get user token from storage or session
            const token = localStorage.getItem("token") || sessionStorage.getItem("token");
            const response = await fetch("https://wishgeekstechserve.vercel.app/api/admin-user/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({ email, password, role }),
            });
            if (response.ok) {
                const newUser = await response.json();
                setUsersData((prevUsers) => [...prevUsers, newUser.newUser]);
                setShowAddUserModal(false);
                setEmail("");
                setPassword("");
                setRole("agent");
            } else {
                console.error("Failed to add user");
            }
        } catch (error) {
            console.error("Error adding user:", error);
        }
    };


    return (
        <>
            {showAddUserModal && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg max-w-sm w-full shadow-lg">
                        <h2 className="text-xl font-semibold mb-4">Add New Admin User</h2>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-2 mb-2 border border-gray-300 rounded"
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full p-2 mb-2 border border-gray-300 rounded"
                        />
                        <select
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className="w-full p-2 mb-4 border border-gray-300 rounded"
                        >
                            <option value="super-admin">Super Admin</option>
                            <option value="agent">Agent</option>
                        </select>
                        <div className="flex justify-end">
                            <button
                                onClick={() => setShowAddUserModal(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleAddUser}
                                className="bg-blue-600 text-white px-4 py-2 rounded"
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            )}



            <YesNoModal
                isOpen={showYesNoModal}
                message="Are you sure you want to delete this user?"
                onYes={confirmDelete}
                onNo={cancelDelete}
                isLoading={smallLoader}
            />
            <div className="container h-[100vh] overflow-y-auto bg-white p-4 mt-24">
                <div className="flex items-center justify-between mb-4 mt-3 border-b border-gray-300">
                    <h1 className="text-3xl font-medium text-gray-500">Users</h1>
                    <button onClick={() => setShowAddUserModal(true)} title="Create new admin user" className="mb-2 bg-[#338dfb] text-white px-4 py-2 rounded-lg hover:bg-[#237de8] transition-colors duration-300">
                        <span>Create</span>
                        <IoMdAddCircleOutline className="inline ml-2" style={{ fontSize: "21px" }} />
                    </button>
                </div>

                <div className="overflow-x-auto">
                    {loading ? (
                        <div className="h-full w-full flex items-center justify-center py-4">
                            <Loader />
                        </div>
                    ) : (
                        <table className="min-w-full divide-y divide-gray-200 bg-white mb-5">
                            <thead className="bg-[#338dfb] text-white">
                                <tr>
                                    <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                        Id
                                    </th>
                                    <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                        Email
                                    </th>
                                    <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                        Role
                                    </th>
                                    <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">

                                    </th>
                                </tr>
                            </thead>

                            <tbody className="bg-white divide-y divide-gray-200">
                                {usersData.length > 0 ? (
                                    usersData.map((user) => (
                                        <tr key={user._id}>
                                            <td className="px-6 py-4 text-xs border font-medium">
                                                {user._id}
                                            </td>
                                            <td className="px-6 py-4 text-xs border font-medium">
                                                {user.email}
                                            </td>
                                            <td className="px-6 py-4 text-xs border font-medium">
                                                <select
                                                    title="Change admin user role"
                                                    value={user.role}
                                                    onChange={(e) =>
                                                        handleRoleChange(user._id, e.target.value)
                                                    }
                                                    className="border border-gray-300 rounded p-1 cursor-pointer"
                                                >
                                                    <option value="super-admin">Super Admin</option>
                                                    <option value="agent">Agent</option>
                                                </select>
                                            </td>
                                            <td className="px-6 py-4 text-xs border font-medium">
                                                <MdDelete title="Delete this admin user" className="text-red-500 text-[26px] hover:text-red-700 cursor-pointer" onClick={() => triggerYesNoModal(user._id)} />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="6"
                                            className="px-6 py-4 text-xs text-gray-500 text-center"
                                        >
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    );
};

export default AdminUsers;
