import React from "react";
import { useState, useEffect } from 'react';
import logo from "../../assests/image/wishi.png";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { IoMdNotifications } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { createWebSocketConnection } from "../../utils/websocket";
import notificationSoundSrc from '../../assests/notification.mp3'

const Header = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [hasOpened, setHasOpened] = useState(false); // Track if the panel has been opened

  // Check if the user is logged in by checking for a token in localStorage or sessionStorage
  const isLoggedIn = !!localStorage.getItem("token") || !!sessionStorage.getItem("token");

  // Load notifications for the current user from local storage
  useEffect(() => {
    const storedNotifications = JSON.parse(localStorage.getItem("notifications")) || [];
    setNotifications(storedNotifications);
    setUnreadCount(storedNotifications.filter(n => !n.read).length); // Count unread notifications
  }, []);

  const handleLogout = () => {
    // Remove the token from localStorage and sessionStorage on logout
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");

    navigate("/");
  };

  // Load notification sound
  const notificationSound = new Audio(notificationSoundSrc);

  useEffect(() => {
    const handleWebSocketMessage = ({ type, content }) => {
      if (type === "newEnquiry") {
        // Play notification sound
        notificationSound.play().catch(error => {
          console.error("Error playing sound:", error);
        });

        const newNotification = {
          message: "New enquiry made",
          id: content._id,
          firstName: content.firstName,
          createdAt: content.createdAt,
          read: false, // Set as unread by default
        };
        const updatedNotifications = [...notifications, newNotification];
        setNotifications(updatedNotifications);
        // Update local storage
        localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
        setUnreadCount(prevCount => prevCount + 1); // Increment unread count
      }
    };

    const cleanup = createWebSocketConnection(handleWebSocketMessage);
    return cleanup;
  }, [notifications]);

  const toggleNotificationPanel = () => {
    setIsOpen((prev) => {
      if (prev) {
        // Reset unread count when the panel is opened
        setUnreadCount(0);
        // Mark notifications as read
        const updatedNotifications = notifications.map(notification => ({ ...notification, read: true }));
        setNotifications(updatedNotifications);
        localStorage.setItem("notifications", JSON.stringify(updatedNotifications)); // Update local storage
      } else {
        setHasOpened(true); // Mark that the panel has been opened
      }
      return !prev;
    });
  };

  const removeNotification = (id) => {
    const updatedNotifications = notifications.filter((notification) => notification.id !== id);
    setNotifications(updatedNotifications);
    localStorage.setItem("notifications", JSON.stringify(updatedNotifications)); // Update local storage
  };

  return (
    <div className="bg-white shadow-lg fixed top-0 left-0 right-0 z-50">
      <div className="container-fluid mx-auto px-4 md:px-20">
        <div className="flex justify-between items-center py-2">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="w-44" />
          </div>
          <div className="flex-1 text-center">
            <p className="text-[#338DFB] font-bold text-3xl md:text-4xl mt-3 leading-snug tracking-tight">
              Techserve Business Success
            </p>
          </div>
          <div className="flex items-center">
            <div className="text-[#338DFB] hover:text-[#237de8] cursor-pointer relative">
              <IoMdNotifications
                style={{ fontSize: "32px", marginRight: "18px" }}
                onClick={toggleNotificationPanel}
                title="Click to open notification panel"
              />
              {unreadCount > 0 && (
                <span className="absolute top-0 right-2 bg-red-500 text-white rounded-full text-xs px-1">
                  {unreadCount}
                </span>
              )}
              <div
                className={`absolute right-0 mt-3 w-72 bg-white rounded-lg shadow-lg border border-gray-200 transition-all duration-300 ease-in-out ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'
                  }`}
              >
                <ul className="divide-y divide-gray-200 p-0 my-1">
                  {notifications.length > 0 ? (
                    notifications.map((notification) => (
                      <li
                        key={notification.id}
                        className={`px-4 py-2 hover:bg-gray-50 ${!notification.read ? 'bg-blue-100 font-semibold' : ''}`}
                      >
                        <div className="flex justify-between items-center">
                          <span className="text-sm text-gray-800">
                            {`${notification.message} by ${notification.firstName}`}
                          </span>
                          <IoMdClose
                            className="text-gray-400 hover:text-red-500 cursor-pointer text-xl"
                            onClick={() => removeNotification(notification.id)}
                          />

                        </div>
                        <div className="block text-xs text-gray-500 mt-2">
                          {formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })}
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className="p-4 text-center text-sm text-gray-500">No new notifications</li>
                  )}
                </ul>
              </div>
            </div>
            {isLoggedIn ? (
              <button
                className="bg-[#338DFB] hover:bg-[#237de8] text-white px-4 py-2 rounded-lg shadow-md font-semibold"
                onClick={handleLogout}
              >
                Logout
              </button>
            ) : (
              <button className="bg-gradient-to-r from-[#7cc8e8] to-[#109ad6] text-white px-4 py-2 rounded-lg shadow-md font-semibold">
                Sign up
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
